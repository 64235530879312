import request from '@/utils/request'


// 查询新-用户邀请码列表
export function listInvitation(query) {
  return request({
    url: '/cigarette/user-invitation/list',
    method: 'get',
    params: query
  })
}

// 查询新-用户邀请码分页
export function pageInvitation(query) {
  return request({
    url: '/cigarette/user-invitation/page',
    method: 'get',
    params: query
  })
}

// 查询新-用户邀请码详细
export function getInvitation(data) {
  return request({
    url: '/cigarette/user-invitation/detail',
    method: 'get',
    params: data
  })
}

// 新增新-用户邀请码
export function addInvitation(data) {
  return request({
    url: '/cigarette/user-invitation/add',
    method: 'post',
    data: data
  })
}

// 修改新-用户邀请码
export function updateInvitation(data) {
  return request({
    url: '/cigarette/user-invitation/edit',
    method: 'post',
    data: data
  })
}

// 删除新-用户邀请码
export function delInvitation(data) {
  return request({
    url: '/cigarette/user-invitation/delete',
    method: 'post',
    data: data
  })
}
