<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入备注" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="Invitation Code" prop="invitationCode" >
        <a-input v-model="form.invitationCode" :maxLength="4" placeholder="Please enter a 4 digits or letters" />
        <div style="display: flex;justify-content: flex-end">{{ ((form.invitationCode && form.invitationCode.length) || 0) }}/4</div>
      </a-form-model-item>
      <a-form-model-item label="Code type" prop="type" >
        <a-select v-model="form.type">
          <a-select-option :key="0">Operator Code</a-select-option>
          <a-select-option :key="1">User Code</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Brand" prop="brandId" >
        <a-select
          placeholder="-Brand-"
          style="width: 100%"
          showSearch
          v-model="form.brandId"
          optionFilterProp="title"
        >
          <a-select-option v-for="item in brandListData" :key="item.id" :value="item.id" :title="item.name">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Useful Time" prop="userfulTime" >
        <a-select v-model="form.userfulTime">
          <a-select-option :key="0">Unlimited</a-select-option>
          <a-select-option :key="1">Once Time</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Code Notes" prop="notes" >
        <a-input v-model="form.notes" placeholder="" maxLength="50" type="textarea" allow-clear />
        <div style="display: flex;justify-content: flex-end">{{ ((form.notes && form.notes.length) || 0) }}/50</div>
      </a-form-model-item>
<!--      <a-form-model-item label="用户id 关联{zb_user.id}" prop="userId" >-->
<!--        <a-input v-model="form.userId" placeholder="请输入用户id 关联{zb_user.id}" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="邀请码使用次数" prop="userNum" >-->
<!--        <a-input v-model="form.userNum" placeholder="请输入邀请码使用次数" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="状态码：1-正常 0失效" prop="status" >-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            Save
          </a-button>
          <a-button type="dashed" @click="cancel">
            Cancel
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getInvitation, addInvitation, updateInvitation } from '@/api/cigarette/invitation'
import {listBrand} from "@/api/cigarette/brand";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        invitationCode: null,

        type: null,

        userfulTime: null,

        notes: null,

        brandId: null,

        userId: null,

        userNum: null,

        status: 1

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      },
      brandListData:[]
    }
  },
  filters: {
  },
  created () {
    this.getBrandSkuList();
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    getBrandSkuList() {
      listBrand({
        status:1
      }).then(res => {
        this.brandListData = res.data;
      })
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        invitationCode: null,
        type: null,
        userfulTime: null,
        notes: null,
        brandId: null,
        userId: null,
        userNum: null,
        status: 1
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = 'Add'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getInvitation({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = 'Modify'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateInvitation(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addInvitation(this.form).then(response => {
              if(response.code == 20001) {
                this.$message.success(
                  'success',
                  3
                )
              } else {
                this.$message.error(
                  response.message,
                  3
                )
              }

              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
